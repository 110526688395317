<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="home" style="background-color:#efeff4">
    <!-- <h3 style="text-align: center;color:#999;margin-bottom:10px;">网站名称</h3> -->
    <div style="text-align: center;" v-if="webinfo.webLogo!=''">
      <van-image :src=" cdnUrl+ '/' + webinfo.webLogo" />
    </div>
    <van-form @submit="onSubmit">
      <van-field v-if="$route.query.isAdmin=='true'" v-model="username" type="text" name="账号" label="账户" placeholder="请输入账户"
        :rules="[{ required: true, }]" />
      <van-field v-if="$route.query.isAdmin=='true'" v-model="password" type="password" name="密码" label="密码" placeholder="请输入密码"
        :rules="[{ required: true, }]" />
      <van-field v-else v-model="password" type="password" name="密码" label="查看密码" placeholder="请输入查看密码"
        :rules="[{ required: true, }]" />
      <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import jiazheng from "@/mixins/jiazheng";
import { Toast } from "vant"
export default {
  mixins: [jiazheng],
  data: function () {
    return {
      openid: '',
      username: '',
      password: '',
      verifyKey:'',
    };
  },
  watch: {

  },
  created() {
    if(this.$route.query.isAdmin){
      localStorage.setItem('isAdmin', 'true');
      this.request({
        url:  "/captcha/get",
        method: "get",
      }).then(res => {
        this.verifyKey = res.data.idKeyC
      })
    }
    if(this.$route.query.openid){
      this.openid=this.$route.query.openid
      localStorage.setItem('openid', this.$route.query.openid);
      this.onSubmit('openid')
    }
    if(localStorage.getItem('token')!=null){
      if (this.$route.query.redirectUrl) {
        window.location.href="/#"+this.$route.query.redirectUrl;
      } else {
        this.$router.push({ path: "/index", query: { area: this.area} });
      }
    }
  },
  methods: {
    onSubmit: function (type='userpass') {
      let url ='',data={};
      if (this.$route.query.isAdmin) {
        url =  "/system/login"
        data = {
          username: this.username,
          openid: this.openid,
          password: this.password,
          verifyKey: this.verifyKey,
        }
      }else{
        url =  "/ayi/login"
        data = {
          password: this.password
        }
      }
      this.request({
        url: url,
        method: "post",
        data:data,
      }).then(res => {
        if (res.code == -1) {
          if (type=='userpass') {
            Toast(res.msg)
          }
        } else {
          localStorage.setItem('token', res.data.token);
          Toast('登陆成功')
          setTimeout(() => {
            if (this.$route.query.redirectUrl) {
              // console.log("/#"+decodeURI(this.$route.query.redirectUrl))
              window.location.href="/#"+decodeURI(this.$route.query.redirectUrl);
            } else {
              this.$router.push({ path: "/index", query: { area: this.area} });
            }
          }, 1500)
        }
        console.log(res)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: calc(100vh - 120px);
  padding:120px 20px 0 20px;
}

/deep/ .van-image {
  img {
    width: 120px;
    border-radius: 50%;
    margin-bottom:10px;
  }
}
/deep/ .van-cell{
  border-radius:3px;
}
.workExperience {}

/deep/ {
  .workExperience {
    color: #969799;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 15px;
      color: #646566;
    }
  }
}

.imgs {
  display: flex;

  .van-image {
    width: calc(50% - 20px);

    &:nth-child(2n) {
      margin-left: 20px;
    }
  }
}
</style>